













































import {Component, Mixins, Watch} from "vue-property-decorator";
import SessionModule from "@/store/SessionModule";
import {getModule} from "vuex-module-decorators";
import DrawerModule from "@/store/DrawerModule";
import LangModule from "@/store/LangModule";
import DrawerList from "@/services/tool/DrawerList";
import Authority, {AuthorityName} from "@/models/Authority";
import CompanyModule from "@/store/CompanyModule";
import ConfigModule from "@/store/ConfigModule";
import SessionMixin from "@/mixins/SessionMixin";
import RouteGroups from "@/services/tool/RouteGroups";
import DrawerItem from "@/models/vue/DrawerItem";
import Company from "@/models/Company";
import {Route} from "vue-router";

@Component
export default class DrawerComponent extends Mixins(SessionMixin) {

	sessionModule: SessionModule = getModule(SessionModule)
	drawerModule: DrawerModule = getModule(DrawerModule)
	companyModule: CompanyModule = getModule(CompanyModule)

	authority?: string = this.sessionModule.session.authorities[0].name
	get configs() { return getModule(ConfigModule).configs }
	avatarInitials: string = ""
	lang = getModule(LangModule).lang

	get isCrmEnabled() { return this.configs.enabled_crm?.value === 'true' }
	get isCompanyCrm() { return this.companyModule.company?.isCrm }
	get isUserCrm() { return this.session.user?.isCrm }

	get isHeadquartersEnabled() { return this.configs.enabled_headquarters?.value === 'true' }

  //TODO: Convert to a set to prevent duplicated items
	get list(): DrawerItem[] {
		switch (this.authority) {

			case (AuthorityName.ADMIN): {return DrawerList.adminList}

			case (AuthorityName.SUPER_ADMIN): {return DrawerList.superAdminList}

			case (AuthorityName.MARKETING_MANAGER): {return DrawerList.marketingManagerList}

			case (AuthorityName.MANAGER): {return DrawerList.managerList}

			case (AuthorityName.COMMERCIAL): {
				const routes = DrawerList.commercialList
				if (this.isCrmEnabled && this.isCompanyCrm && this.isUserCrm) {routes.push(...RouteGroups.crmRoutes)}
				return routes
			}

			case (AuthorityName.DISTRIBUTOR): {
        let routesSet = new Set<DrawerItem>()
				let routes = DrawerList.distributorList

				if (this.isCrmEnabled && this.isCompanyCrm) {
					routes = [...routes, ...RouteGroups.crmRoutes]
				}

        if (this.isHeadquartersEnabled) {
          routes = [...routes, ...RouteGroups.headquarterRoutes]
        }

        console.log("DISTRIBUTOR ROUTES")

        routes.forEach((route) => {
          routesSet.add(route)
        })

        return Array.from(routesSet)
			}

			case (AuthorityName.COMPANY_MANAGER): {
        let routesSet = new Set<DrawerItem>()
				let routes = DrawerList.commercialList

				if (this.isCrmEnabled && this.isCompanyCrm) {
					routes = [...routes, ...RouteGroups.crmRoutes]
				}

        if (this.isHeadquartersEnabled) {
          routes = [...routes, ...RouteGroups.headquarterRoutes]
        }

        console.log("COMPANY MANAGER ROUTES")

        routes.forEach((route) => {
          routesSet.add(route)

        })

				return Array.from(routesSet)
			}

			default: { return RouteGroups.homeRoutes }
		}
	}

	created() {
		this.createAvatarInitials()
	}

	get filteredDrawerItems() {
		return this.filterDrawer(this.session.authorities)
	}

	get mini() {
		return this.drawerModule.mini
	}

	set mini(enabled: boolean) {
		this.drawerModule.setMiniDrawerEnabled(enabled)
	}

	filterDrawer(authorities: Authority[]): DrawerItem[] {
		let list: DrawerItem[] = []
		this.list.forEach(item => {

			if (authorities.some(authority => item.exclusions?.includes(authority.name!!))) {
				return
			}

			list.push(item)

		})
		return list
	}

	createAvatarInitials() {
			let name: string = this.sessionModule.session.user.name!;
			let lastname: string = this.sessionModule.session.user.lastname!
			let n: string = (name != null && name.length > 0) ? name.substring(0, 1).toUpperCase() : ""
			let l: string = (lastname != null && lastname.length > 0) ? lastname.substring(0, 1).toUpperCase() : ""
			if (n.length == 0 && l.length == 0) {
				this.avatarInitials = this.sessionModule.session.user.email!.substring(0, 1).toUpperCase()
			} else {
				this.avatarInitials = n + l;
			}
			// this.avatarInitials = "Me";
		}

}
