export default {

    // Login
    loginIntoYourAccount: "Login",
    email: "Email",
    emails: "Emails",
    password: "Password",
    access: "Access",

    // Home
    welcome: "Welcome to ",
    help: "For help with this panel, please contact us via",

    // Menu
    home: "Home",
    catalog: "Catalog",
    products: "Products",
    productsCategory: "Product Categories",
    brands: "Brands",
    sales: "Sales",
    companies: "Companies",
    myCompany: "My Company",
    orders: "Orders",
    specialSales: "Special sales",
    specialSale: "Special sale",
    videos: "Videos",
    banners: "Banners",
    notifications: "Notifications",
    settings: "Settings",
    synchronization: "Synchronization",
    users: "Users",
    exclusions: "Exclusions",

    restrictAreas: "Restrict Areas",
    clients: "Clients",

    // My Company
    companyFile: "Company File",
    distributors: "Distributors",
    address: "Address",
    province: "Province",
    rate: "Rate",
    rates: "Rates",

    // Commercials
    commercials: "Commercials",
    commercialsDeactivated: "Commercials Deactivated",
    myCommercials: "My Commercials",
    newCommercial: "New Commercial",

    // Clients
    myClients: "My clients",
    newClient: "New client",
    clientsDeactivated: "Clients deactivate",

    // My bills
    myBills: "My bills",

    // My orders
    filters: "Filters",
    newOrder: "New Order",
    send: "Send",
    cloneOrder: "Clone order",
    back: "Back",
    quantity: "Quantity",
    product: "Product",

    // Order detail
    orderDetails: "Order details",
    deliveryAddress: "Delivery Address",

    // Promos
    newPromo: "new Promo",

    // Videos
    newVideo: "New Video",
    views: "Views",

    // Banners
    newBanner: "New Banner",

    // Notifications
    newNotification: "New Notification",

    // Synchronize
    synchronizeCompanies: "Synchronize Companies",
    synchronizeProducts: "Synchronize Products",
    start: "Start",
    end: "End",

    // Users
    viewInactive: "View Inactive",

    // Exclusions
    newExclusion: "New Exclusion",

    // Profile
    profile: "Profile",
    personalFile: "Personal file",
    myOrders: "My Orders",
    security: "Security",
    changePassword: "Change Password",
    contactInformation: "Contact Information",

    // Generic
    search: "Search",
    image: "Image",
    username: "Username",
    name: "Name",
    lastname: "Lastname",
    code: "Code",
    brand: "Brand",
    reference: "Reference",
    category: "Category",
    subCategory: "Subcategory",
    fiscalName: "Fiscal Name",
    cif: "CIF",
    nif: "NIF",
    date: "Date",
    createdBy: "Created By",
    document: "Document",
    documents: "Documents",
    update: "Update",
    status: "Status",
    noResults: "No Results",
    url: "Url",
    hour: "Hour",
    type: "Type",
    subject: "Subject",
    receiver: "Receivers",
    commercialName: "Commercial Name",
    zone: "Zone",
    productCategory: "Product Category",
    actions: "Actions",
    pathName: "Path Name",
    path: "Path",
    phone: "Phone",
    phones: "Phones",
    logout: "Logout",
    quit: "Cancel",
    none: "none",
    loading: "Loading...",
    edit: "Edition",
    barcodes: "Barcodes",
    number: "Number",
    price: "Price",
    content: "Content",
    description: "Description",
    observations: "Observations",
    showOnWeb: "Show on web",
    showingOnWeb: "Showing on web",
    notShowingOnWeb: "Not showing on web",
    featured: "Featured",
    ivaType: "IVA Type",
    dragImage: "Drag the image here",
    dragDocument: "Drag the document here",
    newBarcode: "New Barcode",
    addNewBarcode: "Add new barcode",
    save: "Save",
    categories: "Categories",
    barcodeRequired: "Barcode Required",
    productCategories: "Product Categories",
    newCategory: "New Category",
    create: "Create",
    referenceRequired: "Reference is required",
    delete: "Delete",
    warning: "Warning",
    productCategoryDeleteQuestion: "Are you sure to delete the product category?",
    productCategoryUpdateQuestion: "Are you sure to update the product category?",
    accept: "Accept",
    typeToSearch: "Type to search...",
    sent: "Sent",
    finalized: "Finalized",
    draft: "Draft",
    onHold: "On Hold",
    documentRequired: "Document Required",
    promoCreateQuestion: "Are you sure to create the promotion?",
    promoUpdateQuestion: "Are you sure to update the promotion?",
    promoDeleteQuestion: "Are you sure to remove this promotion?",
    urlRequired: "Url required",
    urlNoValid: "Url is no valid",
    videoCreateQuestion: "Are you sure to create the video?",
    videoUpdateQuestion: "Are you sure to update the video?",
    nameRequired: "Name required",
    typeRequired: "Type required",
    imageRequired: "Image required",
    bannerCreateQuestion: "Are you sure to create the banner?",
    notificationCreateQuestion: "Are you sure you want to create a notification?",
    message: "Message",
    syncCompanies: "Sync companies",
    syncProducts: "Sync products",
    newUser: "New User",
    newAdminUser: "New Admin User",
    emailRequired: "Email required",
    emailNoValid: "Email no valid",
    nameMin2Characters: "The name must be at least 2 characters long",
    userCreateQuestion: "Are you sure to create the user?",
    deactivate: "Deactivate",
    activate: "Activate",
    restrictedAreas: "Restricted Areas",
    passwordUpdateQuestion: "Are you sure to change the password?",
    userDeactivateQuestion: "Are you sure to deactivate this user?",
    userActivateQuestion: "Are you sure to activate this user?",
    createExclusion: "New Exclusion",
    editExclusions: "Edit Exclusion",
    fieldRequired: "Field required",
    exclusionDeleteQuestion: "Do you want to remove this exclusion?",
    screens: "Screens",
    screenHome: "Home Screen",
    toRestrict: "To restrict",
    active: "Active",
    inactive: "Inactive",
    deactivatedCommercials: "Commercials Deactivated",
    commercialFile: "Commercial File",
    disabledClients: "Disabled Clients",
    contactName: "Contact Name",
    pleaseFillField: "Please fill in this field",
    fieldMin4Characters: "The field must be at least 4 characters long",
    fieldMin2Characters: "The field must be at least 2 characters long",
    fieldMax256Characters: "The field must be a maximum of 256 characters",
    clientCreateQuestion: "Are you sure to create the client?",
    shippingAddress: "Shipping address",
    postalCode: "Postal code",
    population: "Population",
    deleteOrderQuestion: "Do you want to delete this order?",
    sendOrderQuestion: "Are you sure to send the order?",
    total: "Total",
    pvp: "PVP",
    sentBy: "Sent By",
    client: "Client",
    pvpPrices: "PVP Price",
    sendToCentral: "Send to central",
    placeAnOrder: "Place an order",
    addProduct: "Add Products",
    ratePrice: "Rate Price",
    editAnOrder: "Edit and order",
    saveOrder: "Save Order",
    add: "Add",
    placeOrderQuestion: "Are you sure you place the order?",
    addProductToContinue: "you must add at least one product to continue",
    saveOrderQuestion: "Do you want to save this order?",
    sendOrderToCentralQuestion: "Do you want to send this order to central?",
    createOneClientQuestion: "There are no clients created, do you want to go create one?",
    addedProduct: "Added Product",
    close: "Close",
    invoice: "Invoice",
    waiting: "Waiting",

    currentPassword: "Current Password",
    replacePassword: "Replace my password",
    newPassword: "New Password",
    repeatPassword: "Repeat Password",
    matchPasswords: "Passwords must match",
    repeatPasswordRequired: "Repeat password required",
    newPasswordRequired: "New password required",
    password4CharactersRequired: "New password of at least 8 characters",

    notificationEmail: "Email for notifications",
    buyDateAtHeader: "Purchase Date",
    searching: "Searching...",
    filterByProduct: "Filter by product",
    filterByDate: "Filter by date",
    filterByStatus: "Filter by status",
    filterByClient: "Filter by client",
    filterByCommercial: "Filter by commercial",
    clearFilters: "Clear filters",
    ordersTableTip: "Orders maintains their position on list even after got edited.",
    generalDetails: "General details",

    position: "Position",
    imageNotAvaliable: "Imagen not avaliable",
    blocked: "Blocked",
    xgestStatus: "Xgest status",
    user: "User",
    syncCompany: "Synchronize company",
}