import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators"
import Notification from "@/models/Notification"
import store from "@/store/index"
import axios from "axios"
import NotificationService from "@/services/NotificationService";
import SessionModule from "@/store/SessionModule";

@Module({ dynamic: true, store, name: "NotificationsModule" })
export default class NotificationsModule extends VuexModule {

    timeout: number = 10000
    interval: any = null
    notifications: Notification[] = []
    page: number = 0
    loading: boolean = false

    @Mutation
    async startRefreshing() {
        const injectNotifications = async () => {
            this.loading = true
            const notifications = await NotificationService.getMyNotifications(0, 5)
            notifications.result.forEach(notification => {
                if (this.notifications.filter(n => n.id == notification.id).length == 0) { this.notifications.push(notification) }
            })
            this.loading = false
        }
        
        await injectNotifications()

        this.interval = setInterval(async () => {
            await injectNotifications()
        }, this.timeout)

        this.notifications.sort((a, b) => b.createdAt!!.toMillis() - a.createdAt!!.toMillis())
    }

    @Mutation
    async nextPage() {
        this.page = this.page + 1
        const notifications = await NotificationService.getMyNotifications(this.page, 5)
        notifications.result.forEach(notification => {
            if (this.notifications.filter(n => n.id == notification.id).length == 0) {
                this.notifications.push(notification)
            }
        })
    }

    @Mutation
    clear() {
        clearInterval(this.interval)
        this.interval = null
        this.notifications.splice(0, this.notifications.length)
    }
}