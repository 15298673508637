import {Component, Vue} from "vue-property-decorator"
import SessionTool from "@/services/tool/SessionTool"
import {AuthorityName} from "@/models/Authority"

@Component
export default class SessionMixin extends Vue {

    session = SessionTool.getSession()

    get isDistributor() { return SessionTool.hasAuthority(AuthorityName.DISTRIBUTOR) }
    get isCompanyManager() { return SessionTool.hasAuthority(AuthorityName.COMPANY_MANAGER) }
    get isCommercial() { return SessionTool.hasAuthority(AuthorityName.COMMERCIAL) }
    get isMarketingManager() { return SessionTool.hasAuthority(AuthorityName.MARKETING_MANAGER) }

}