import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from "@/config/vuetify"
import AxiosConfig from "@/config/AxiosConfig"
import MapsConfig from "@/config/MapsConfig"
import ImageInputConfig from "@/config/ImageInputConfig"
import SilentBoxConfig from "@/config/SilentBoxConfig"
import CountryFlagConfig from "@/config/CountryFlagConfig"
//@ts-ignore
import VueLineClamp from 'vue-line-clamp'
import vueDebounce from 'vue-debounce'
import CompanyService from "@/services/CompanyService"
//@ts-ignore
import LazyTube from "vue-lazytube"
// @ts-ignore
import VueSSE from 'vue-sse'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import LoginService from "@/services/LoginService";

Vue.config.productionTip = false
Vue.use(VueLineClamp)
Vue.use(vueDebounce)
Vue.use(LazyTube)
Vue.use(VueSSE)
Vue.use(PerfectScrollbar)
const vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

AxiosConfig.init(vue)
MapsConfig.init(vue)
ImageInputConfig.init(vue)
SilentBoxConfig.init(vue)
CountryFlagConfig.init(vue)