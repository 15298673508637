import {JsonConverter, JsonCustomConvert, JsonObject, JsonProperty} from "json2typescript";

export enum ConfigType {
    ENABLE_CRM = "ENABLE_CRM",
    ALLOW_COMMERCIALS = "ALLOW_COMMERCIALS",
    ALLOW_COMMERCIALS_IN_PANEL = "ALLOW_COMMERCIALS_IN_PANEL",
    MAINTENANCE_MODE = "MAINTENANCE_MODE",
    ENABLE_HEADQUARTERS = "ENABLE_HEADQUARTERS",
}

@JsonConverter
class ConfigConverter implements JsonCustomConvert<ConfigType> {
    deserialize(data: string): ConfigType { return (<any>ConfigType)[data] }
    serialize(data: ConfigType): any { return data.toString() }
}

@JsonObject("Config")
export default class Config {

    @JsonProperty("key", ConfigConverter, true)
    key?: ConfigType = undefined
    @JsonProperty("value", String, true)
    value?: string = undefined

}