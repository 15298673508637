import Vue from 'vue'
import Router from 'vue-router'
import {AuthorityName} from "@/models/Authority";
import SessionModule from "@/store/SessionModule";
import LangModule from "@/store/LangModule";
import {getModule} from "vuex-module-decorators";
import BreadcrumbsModule from "@/store/BreadcrumbsModule";
import LoginService from "@/services/LoginService";

Vue.use(Router)

const router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', name: 'home', component: () => import("@/views/HomeView.vue"), meta: { guest: true, authorize: [AuthorityName.USER, AuthorityName.ADMIN, AuthorityName.DISTRIBUTOR, AuthorityName.COMMERCIAL, AuthorityName.SUPER_ADMIN] } },
    { path: '/login', name: 'login', component: () => import("@/views/LoginView.vue"), meta: { guest: false, }},
    { path: '/password-recovery', name: 'Nueva Contraseña', component: () => import("@/views/PasswordRecoveryView.vue"), meta: { guest: false, } },
    { path: '/users', name: 'Usuarios', component: () => import("@/views/UsersView.vue"), meta: { guest: true, } },
    { path: '/users/@me', name: 'Mi Usuario', component: () => import("@/views/UserMeView.vue"), meta: { guest: true, } },
    { path: '/users/:userId(\\d+)(/restricted-areas)?(/orders)?', name: 'Usuario', component: () => import("@/views/UserView.vue"), meta: { guest: true, } },
    { path: '/commercials', name: 'Comerciales', component: () => import("@/views/CommercialsView.vue"), meta: { guest: true, } },
    { path: '/commercials/:id(\\d+)(/orders)?', name: 'Comercial', component: () => import("@/views/CommercialView.vue"), meta: { guest: true, } },
    { path: '/clients', name: 'Clientes', component: () => import("@/views/ClientsView.vue"), meta: { guest: true, } },
    { path: '/potential-clients', name: 'Clientes potenciales', component: () => import("@/views/PotentialClientsView.vue"), meta: { guest: true, } },
    { path: '/potential-clients/:id', name: 'Cliente potencial', component: () => import("@/views/PotentialClientView.vue"), meta: { guest: true, } },
    { path: '/my-budgets', name: 'Mis Presupuestos', component: () => import("@/views/MyBudgetsView.vue"), meta: { guest: true, } },
    { path: '/budget(/products)?', name: 'Crear un presupuesto', component: () => import("@/views/BudgetView.vue"), meta: { guest: true, } },
    { path: '/budget/:orderId/edit(/products)?', name: 'Editar un presupuesto', component: () => import("@/views/BudgetView.vue"), meta: { guest: true, } },
    { path: '/budget', name: 'Presupuesto', component: () => import("@/views/BudgetView.vue"), meta: { guest: true, } },
    { path: '/activities', name: 'Actividades', component: () => import("@/views/EventsView.vue"), meta: { guest: true, } },
    { path: '/activities/create', name: 'Nueva Actividad',  component: () => import("@/views/CreateEventView.vue"), meta: { guest: true, } },
    { path: '/activities/:id', name: 'Editar Actividad',  component: () => import("@/views/CreateEventView.vue"), meta: { guest: true, } },
    { path: '/restricted-areas', name: 'Areas restringidas', component: () => import("@/views/PanelScreenView.vue"), meta: { guest: true, } },
    { path: '/clients/:id', name: 'Cliente', component: () => import("@/views/ClientView.vue"), meta: { guest: true, } },
    { path: '/orders', name: 'Pedidos', component: () => import("@/views/OrdersView.vue"), meta: { guest: true, } },
    { path: '/budget-details/:orderId', name: 'Detalle de un presupuesto', component: () => import("@/views/BudgetDetailView.vue"), meta: { guest: true, } },
    { path: '/order-details/:orderId', name: 'Detalle de un pedido', component: () => import("@/views/OrderDetailView.vue"), meta: { guest: true, } },
    { path: '/brand', name: 'Marcas', component: () => import("@/views/BrandsView.vue"), meta: { guest: true, } },
    { path: '/collections', name: 'Colecciones', component: () => import("@/views/CollectionsView.vue"), meta: { guest: true, } },
    { path: '/collections/:collectionId(\\d+)(/images)?(/videos)?', name: 'Colección',component: () => import("@/views/CollectionView.vue"), meta: { guest: true, } },
    { path: '/promos', name: 'Promociones',component: () => import("@/views/PromosView.vue"), meta: { guest: true, } },
    { path: '/promos/:promoId(\\d+)', name: 'Promoción', component: () => import("@/views/PromoView.vue"), meta: { guest: true, } },
    { path: '/videos', name: 'Video', component: () => import("@/views/VideosView.vue"), meta: { guest: true, } },
    { path: '/videos/:videoId(\\d+)', name: 'Videos', component: () => import("@/views/VideoView.vue"), meta: { guest: true, } },
    { path: '/product-categories', name: 'Categorias de productos', component: () => import("@/views/ProductCategoriesView.vue"), meta: { guest: true, } },
    { path: '/product-categories/:productCategoryId(\\d+)', name: 'Categoria de producto', component: () => import("@/views/ProductCategoryView.vue"), meta: { guest: true, } },
    { path: '/product-feature-types', name: 'Tipo de característica de producto', component: () => import("@/views/ProductFeatureTypesView.vue"), meta: { guest: true, } },
    { path: '/product-feature-types/:productFeatureTypeId(\\d+)', name: 'Tipos de características de producto', component: () => import("@/views/ProductFeatureTypeView.vue"), meta: { guest: true, } },
    { path: '/product-exclusion', name: 'Exclusiones', component: () => import("@/views/ProductExclusionView.vue"), meta: { guest: true, } },
    { path: '/products', name: 'Productos', component: () => import("@/views/ProductsView.vue"), meta: { guest: true, } },
    { path: '/companies', name: 'Empresas', component: () => import("@/views/CompaniesView.vue"), meta: { guest: true, } },
    { path: '/my-company', name: 'Mi Empresa', component: () => import("@/views/MyCompanyView.vue"), meta: { guest: true, } },
    { path: '/my-orders', name: 'Mis Pedidos', component: () => import("@/views/MyOrdersView.vue"), meta: { guest: true, } },
    { path: '/orders-management', name: 'Gestión de pedidos', component: () => import("@/views/OrdersManagementView.vue"), meta: { guest: true, } },
    { path: '/order-management/:orderId', name: 'Gestión de pedido', component: () => import("@/views/OrderManagementView.vue"), meta: { guest: true, } },
    { path: '/companies/:id(\\d+)(/orders)?(/distributors)?', name: 'Compañia', component: () => import("@/views/CompanyView.vue"), meta: { guest: true, } },
    { path: '/products/:productId(\\d+)(/documents)?(/barcodes)?(/features)?(/rates)?(/categories)?(/langs)?', name: 'Producto', component: () => import("@/views/ProductView.vue"), meta: { guest: true, } },
    { path: '/banners', name: 'Banners', component: () => import("@/views/BannersView.vue"), meta: { guest: true, } },
    { path: '/banners/:bannerId(\\d+)', name: 'Banner', component: () => import("@/views/BannerView.vue"), meta: { guest: true, } },
    { path: '/notifications/:id', name: 'Notificación',  component: () => import("@/views/NotificationView.vue"), meta: { guest: true, } },
    { path: '/notifications', name: 'Notificaciones',  component: () => import("@/views/NotificationsView.vue"), meta: { guest: true, }},
    { path: '/synchronize', name: 'Sincronización',  component: () => import("@/views/SynchronizeView.vue"), meta: { guest: true, } },
    { path: '/synchro/:id', name: 'Sincronización',  component: () => import("@/views/SynchroView.vue"), meta: { guest: true, } },
    { path: '/invoices', name: 'Facturas',  component: () => import("@/views/InvoicesView.vue"), meta: { guest: true, } },
    { path: '/catalogues', name: 'Catalogos',  component: () => import("@/views/CataloguesView.vue"), meta: { guest: true, } },
    { path: '/catalogues/:id', name: 'Catalogo',  component: () => import("@/views/CatalogueView.vue"), meta: { guest: true, } },
    { path: '/config', name: 'Configuración',  component: () => import("@/views/ConfigurationView.vue"), meta: { guest: true, } },
    { path: '/new-order', name: 'Nuevo Pedido',  component: () => import("@/views/NewOrder.vue"), meta: { guest: true, } },
    { path: '/new-order/:orderId/edit', name: 'Editar Pedido',  component: () => import("@/views/NewOrder.vue"), meta: { guest: true, } },
    { path: '/history-data', name: 'Historial de Datos',  component: () => import("@/views/HistoryDataView.vue"), meta: { guest: true, } },
    { path: '/logs', name: 'Logs',  component: () => import("@/views/LogsView.vue"), meta: { guest: true, } },
    { path: '/distributors/:id', name: 'Distribuidor',  component: () => import("@/views/DistributorView.vue"), meta: { guest: true, } },
    { path: '/headquarters', name: 'Sedes',  component: () => import("@/views/HeadquartersView.vue"), meta: { guest: true, } },
    { path: '/headquarters/:id', name: 'Sede',  component: () => import("@/views/HeadquarterView.vue"), meta: { guest: true, } },
    { path: '/headquarter-managers', name: 'Gestores de sedes',  component: () => import("@/views/HeadquarterManagersView.vue"), meta: { guest: true, } },
    { path: '/headquarter-managers/:id', name: 'Gestor de sedes',  component: () => import("@/views/HeadquarterManagerView.vue"), meta: { guest: true, } },
    { path: '/headquarter-orders', name: 'Pedidos de Sedes',  component: () => import("@/views/HeadquarterOrdersView.vue"), meta: { guest: true, } },
    { path: '/headquarter-orders/new-order', name: 'Crear pedidos de Sedes',  component: () => import("@/views/NewHeadquarterOrder.vue"), meta: { guest: true, } },
    { path: '/headquarter-orders/:id/edit', name: 'Editar pedidos de Sedes',  component: () => import("@/views/NewHeadquarterOrder.vue"), meta: { guest: true, } },

    { path: '*', redirect: '/' }
  ]
})

export default router

router.beforeEach(async(to, from, next) => {
  const { authorize } = to.meta

  const langModule = getModule(LangModule)
  langModule.loadLanguage()

  const breadcrumbsModule = getModule(BreadcrumbsModule)

  if (to.name != "home") {
    breadcrumbsModule.addItem({text: to.name, disabled: false, href: to.path})
  }

  let sessionModule: SessionModule = getModule(SessionModule)
  if (sessionModule.session && sessionModule.session.token) {
    sessionModule.saveSession()
  }
  sessionModule.loadSession()
  let authorities = sessionModule.session.authorities

  if (to.meta.guest) {
    if (!LoginService.isLoged()) {
      return next({ path:'/login' })
    }
  }

  // if (!sessionModule.session.token) {
  //   if ((to.path != "/login") && (to.path != "password-recovery")) {
  //     return next({path: "/login", query: {redirect: to.path}})
  //   }
  // } else if ((to.path == "/login")) {
  //   return next({path: "/"})
  // } else if (to.path != "/" && authorize) {
  //   for (let authority of authorities) {
  //     if (authorize.includes(authority.name)) {
  //       return next()
  //     }
  //   }
  //   return next({path: "/"})
  // }

  next()
})
