import {JsonObject, JsonProperty} from "json2typescript";
import PanelScreen from "@/models/PanelScreen";
import firebase from "firebase";
import Auth = firebase.auth.Auth;
import Authority from "@/models/Authority";
import Auditing from "@/models/Auditing";
import DateTimeConverter from "@/services/converters/DateTimeConverter";
import {DateTime} from "luxon";

@JsonObject("User")
export default class User {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("connectorId", String, true)
    connectorId?: string = undefined
    @JsonProperty("email", String, true)
    email?: string = undefined
    @JsonProperty("notificationEmail", String, true)
    notificationEmail?: string = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined
    @JsonProperty("lastname", String, true)
    lastname?: string = undefined
    @JsonProperty("commercialName", String, true)
    commercialName?: string = undefined
    @JsonProperty("commercialsEnabled", Boolean, true)
    commercialsEnabled?: boolean = undefined
    @JsonProperty("dni", String, true)
    dni?: string = undefined
    @JsonProperty("active", Boolean, true)
    active?: boolean = undefined
    @JsonProperty("panelScreens", [PanelScreen], true)
    panelScreens?: PanelScreen[] = undefined
    @JsonProperty("rateId", Number, true)
    rateId?: number = undefined
    @JsonProperty("authorities", [Authority], true)
    authority?: Authority[] = undefined
    @JsonProperty("isCrm", Boolean, true)
    isCrm?: boolean = undefined
    @JsonProperty("notifyPasswordChange", Boolean, true)
    notifyPasswordChange?: boolean = undefined
    @JsonProperty("createdAt", DateTimeConverter, true)
    createdAt?: DateTime = undefined
    @JsonProperty("createdBy", User, true)
    createdBy?: User = undefined


    @JsonProperty("isCompanyManager", Boolean, true)
    isCompanyManager?: boolean = undefined

}

