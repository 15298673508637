import {Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store/index";

@Module({dynamic: true, store, name: "BreadcrumbsModule"})
export default class BreadcrumbsModule extends VuexModule {

    items = [{text: 'Inicio', disabled: false, href: '/'}]


    @Mutation
    addItem(route: any) {
        if (this.items.find(e => e.text == route.text)) {
            let i = this.items.indexOf(this.items.find(e => e.text == route.text)!)
            console.log("La cantidad de items que hay: " + this.items.length + " La cantidad que debo eliminar: " + (this.items.length - i) + " mi posicion: " + i)
            this.items.splice(i, this.items.length - i)
        }
        if (this.items.length > 5) {
            this.items.splice(1, 1)
        }
        if (route.text != "login") {
            this.items.push(route)
        }

    }

    @Mutation
    clear() {
        this.items = [{text: 'Inicio', disabled: false, href: '/'}]
    }

}