















import {Component, Vue} from "vue-property-decorator";
import BreadcrumbsModule from "@/store/BreadcrumbsModule";
import {getModule} from "vuex-module-decorators";

@Component
export default class BreadcrumbsComponent extends Vue {
	breadcrumbsModule: BreadcrumbsModule = getModule(BreadcrumbsModule)
}
