export default {
    exclusionDeleteQuestion: "Quer eliminar esta exclusão?",
    deleteOrderQuestion: "Quer eliminar a encomenda?",
    sendOrderToCentralQuestion: "Deseja enviar para o centro este pedido",
    placeOrderQuestion: "Deseja realizar esta encomenda?",
    userActivateQuestion: "Têm a certeza que quer ativar o utilizador",
    videoUpdateQuestion: "Têm a certeza que quer atualizar o vídeo?",
    productCategoryUpdateQuestion: "Têm a certeza que quer atualizar a familia dos produtos",
    promoUpdateQuestion: "Têm certeza que quer atualizar a promoçãoê",
    passwordUpdateQuestion: "Têm a certeza que quer alterar a palavra-chave",
    bannerCreateQuestion: "Têm a certeza que quer criar o banner?",
    createClientQuestion: "Têm a certeza que que quer criar o cliente?",
    userCreateQuestion: "Têm a certeza que quer criar o utilizador?",
    videoCreateQuestion: "Têm a certeza que quer criar o vídeo?",
    promoCreateQuestion: "Têm a certeza que quer criar a promoção?",
    userDeactivateQuestion: "Têm a certeza que quer desativar o utilizador",
    promoDeleteQuestion: "Têm certeza que quer eliminar a promoção?",
    productCategoryDeleteQuestion: "Têm a certeza que quer eliminar a categoria dos produtos",
    sendOrderQuestion: "Têm a certeza que quer enviar a encomenda?",
    notificationCreateQuestion: "Têm a certeza que quer criar uma notificação?",
    loginIntoYourAccount: "Acede a sua conta",
    access: "Aceder",
    actions: "Ações",
    accept: "Aceitar",
    enable: "Ativar",
    active: "Ativo",
    add: "Adicionar",
    settings: "Configurações",
    addNewBarcode: "Adicione o novo código de barras",
    addProduct: "Adicionar produtos",
    lastname: "Apelido",
    restrictedAreas: "Áreas restritas",
    dragDocument: "Carrega o documento aqui",
    dragImage: "Carrega a imagem aqui",
    subject: "Assunto",
    warning: "Aviso",
    banners: "Banners",
    welcome: "Bem-vindo a ",
    draft: "Apagador",
    search: "Pesquisar",
    changePassword: "Modificar a palavra-chave",
    fieldRequired: "Campo obrigatório",
    quit: "Cancelar",
    quantity: "Quantidade",
    loading: "Carregando",
    catalog: "Catálogo",
    categories: "Categoria",
    productCategory: "Categoria dos produtos",
    productCategories: "Categoria dos produtos",
    category: "Categorias",
    close: "Fechar",
    cif: "NIF empresa",
    client: "Cliente",
    clients: "Clientes",
    clientsDeactivated: "Cliente desativado",
    cloneOrder: "Clonar o pedido",
    code: "Código",
    barcodeRequired: "Código de barras obrigatório",
    postalCode: "Código postal",
    barcodes: "Código de barras",
    commercials: "Comerciais",
    deactivatedCommercials: "Comerciais desativados",
    content: "Conteudo",
    password: "Palavra-chave",
    currentPassword: "Palavra-chave atual",
    createdBy: "Criado por",
    create: "Criar ",
    createExclusion: "Criar uma exclusão",
    newOrder: "Criar pedido novo",
    contactInformation: "Dados de contato",
    addProductToContinue: "Deve adicionar pelo menos um produto para continuar",
    disable: "Desativar",
    description: "Descrição",
    featured: "Destacado",
    receiver: "Destinatario",
    deliveryAddress: "Morada de entrega",
    shippingAddress: "Morada de envio",
    distributors: "Distribuidores",
    document: "Documento",
    documentRequired: "Documento obrigatório",
    documents: "Documentos",
    edit: "Edição",
    editAnOrder: "Editar uma encomenda",
    fieldMax256Characters: "O campo deve ter no máximo 4 caracteres",
    fieldMin2Characters: "O campo deve ter pelo menos 2 caracteres",
    fieldMin4Characters: "O campo deve ter pelo menos 4 caracteres",
    nameMin2Characters: "O nome deve ser mínimo 2 carateres",
    delete: "Eliminar",
    email: "E-mail",
    emailNoValid: "E-mail não válido",
    emailRequired: "E-mail obrigatório",
    emails: "E-mails",
    start: "Começa",
    companies: "Empresas",
    onHold: "Em espera",
    sent: "Enviado",
    sentBy: "Enviado por",
    send: "Enviar",
    sendToCentral: "Enviar para o centro",
    typeToSearch: "Escriver para procurar",
    status: "Estado",
    exclusions: "Exclusões",
    invoice: "Fatura",
    date: "Data",
    commercialFile: "Ficha comercial",
    companyFile: "Ficha da empresa",
    personalFile: "Ficha pessoal",
    filters: "Filtros",
    finalized: "Finalizado",
    save: "Guardar",
    saveOrder: "Guardar a encomenda",
    image: "Imagem",
    imageRequired: "Imagem obrigatória",
    inactive: "Inativo",
    home: "Inicio",
    referenceRequired: "A referência é obrigatória",
    matchPasswords: "As palavras-chave devem coincidir",
    brand: "Marcas",
    brands: "Marcas",
    message: "Mensagem",
    myCompany: "A minha empresa",
    myClients: "Meus clientes",
    myCommercials: "Os meus comersiais",
    myBills: "As minhas faturas",
    myOrders: "As minhas encomendas",
    showOnWeb: "Mostrar no site",
    nif: "NIF Pessoa física",
    none: "Nenhuma",
    createOneClientQuestion: "Não há clientes criados, deseja criar um?",
    noResults: "Nenhum resultado encontrado",
    name: "Nome",
    commercialName: "Nome comercial",
    contactName: "Nome do contato",
    pathName: "Nome do percurso",
    fiscalName: "Nome fiscal",
    nameRequired: "Nome obrigatório",
    notifications: "Notificações",
    newCategory: "Nova categoria",
    newPassword: "Nova palavra-chave",
    password4CharactersRequired: "Nova palavra-chave de mínimo 4 caracteres ",
    newPasswordRequired: "Nova palavra-chave obrigatória",
    newExclusion: "Esclusão nova",
    newNotification: "Notificação nova",
    newPromo: " Promoção nova",
    newBanner: "Banner novo",
    newClient: "Cliente novo",
    newBarcode: "Novo código de barras",
    newUser: "Utilizador novo",
    newAdminUser: "Novo utilizador administrador",
    newCommercial: "Novo utilizador comercial",
    newVideo: "Vídeo novo",
    number: "Número",
    observations: "Observações",
    screenHome: "Excrão de inicio",
    screens: "Ecrãs",
    help: "Para obter ajuda com este painel, entre em contato connosco através de ",
    orders: "Encomendas",
    profile: "Perfil",
    population: "Freguesia",
    pleaseFillField: "Por favor preencher o campo",
    price: "Preço",
    pvpPrices: "Preços PVP",
    product: "Produto",
    addedProduct: "Produto adicionado",
    products: "Proutos",
    specialSale: "Promoção",
    specialSales: "Promoções especiais",
    province: "Distrito",
    pvp: "PVP",
    placeAnOrder: "Efetuar um pedido",
    reference: "Referência",
    repeatPasswordRequired: "Repete a palvra-chave obrigatória",
    repeatPassword: "Repete a palvra-chave",
    toRestrict: "Limitar",
    restrictAreas: "Restringir áreas",
    path: "Rota",
    logout: "Sair",
    security: "Segurança",
    synchronization: "Sincronização",
    syncCompanies: "Sincronizar empresas",
    syncProducts: "Sincronizar produtos",
    subCategory: "Subcategoria",
    rate: "Tarifa",
    rates: "Tarifas",
    phone: "Telefono",
    phones: "Telefonos",
    end: "Acaba",
    type: "Tipo",
    ivaType: "Taxa IVA",
    typeRequired: "Modelo obrigatório",
    total: "Total",
    url: "Url",
    urlNoValid: "Url não válido",
    urlRequired: "Url obrigatório",
    users: "Utilizadores",
    sales: "Vendas",
    viewInactive: "Ver inativos",
    videos: "Vídeos",
    views: "Visualizações",
    back: "Voltar",
    zone: "Zona",
    generalDetails: "",
}