import {config, Module, Mutation, VuexModule} from "vuex-module-decorators"
import ConfigResponse from "@/models/responses/ConfigResponse"
import ConstantTool from "@/services/tool/ConstantTool"
import JsonTool from "@/services/tool/JsonTool"
import store from "@/store/index"
import axios from "axios";

@Module({dynamic: true, store, name: "ConfigModule"})
export default class ConfigModule extends VuexModule {

    configs: ConfigResponse = new ConfigResponse()

    @Mutation
    async refreshConfigs() {
        try {
            const response: any = await axios.get(ConstantTool.BASE_URL + "/public/config")
            this.configs = JsonTool.jsonConvert.deserializeObject(response.data, ConfigResponse)
            console.log(this.configs)
        } catch (e) {
            console.log(e)
        }
    }


}