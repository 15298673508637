


















import {Component, Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";

@Component
export default class FooterComponent extends Vue {
    projectName: string = ConstantTool.PROJECT_NAME
}
