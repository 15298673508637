import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store/index";
import Company from "@/models/Company";
import ConstantTool from "@/services/tool/ConstantTool";
import Session from "@/models/Session";
import CryptoTool from "@/services/tool/CryptoTool";

@Module({dynamic: true, store, name: "CompanyModule"})
export default class CompanyModule extends VuexModule {

    company: Company = new Company()

    @Mutation
    setCompany(company: Company) {
        this.company = company
    }

    @Mutation
    clear() {
        this.company = new Company()
    }

    @Action
    saveCompany() {
        try {
            localStorage.setItem("company", JSON.stringify(this.company))
        } catch (e) {
            console.log("Error while saving company")
        }
    }

    @Action
    loadCompany() {
        try {
            let companyObject = localStorage.getItem("company")
            if (companyObject != null) {
                this.setCompany(JSON.parse(companyObject))
            } else {
                this.clear()
            }
        } catch (e) {
            localStorage.removeItem("company")
            this.clear()
            console.log("Error while loading company")
        }
    }



}