import {JsonObject, JsonProperty} from "json2typescript"
import Config from "@/models/Config"

@JsonObject("ConfigResponse")
export default class ConfigResponse {

    @JsonProperty("enabledCrm", Config, true)
    enabled_crm?: Config = undefined

    @JsonProperty("allowedCommercials", Config, true)
    allowed_commercials?: Config = undefined

    @JsonProperty("allowedCommercialsInPanel", Config, true)
    allowed_commercials_in_panel?: Config = undefined

    @JsonProperty("maintenanceMode", Config, true)
    maintenance_mode?: Config = undefined

    @JsonProperty("enabledHeadquarters", Config, true)
    enabled_headquarters?: Config = undefined

}